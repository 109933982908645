body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

*::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: #f8f6f6;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
}

*::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background-color: #f5f5f5;
}

*::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #bdbdbd;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
}
