/* .holo-page-layout {
  display: flex;
  width: 100%;
  height: 100%;
  background: url(../src/noise-texture-background.svg) no-repeat center;
  background-size: cover;
  min-height: 100vh;
} */

/* Background blobs & lined background */
/* ul.blobs-bg {
  width: 100%;
  height: 100vh;
  overflow: hidden;
  display: flex;
  position: fixed;
}
.blobs-bg::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  inset: 0;
  background: url(../src/line-background.svg) no-repeat top;
  background-size: cover;
}

.blobs-bg li {
  & > div {
    position: absolute;
    opacity: 0.2;
    filter: blur(250px);
  }
}

.ellipse-blue01 {
  right: 0;
  bottom: 30%;
  width: 53rem;
  height: 31rem;
  transform: rotate(-15deg);
  border-radius: 53.48281rem;
  background: #75dafd;
  filter: blur(200px);
}

.ellipse-purple01 {
  left: -5%;
  bottom: -35%;
  width: 34.47388rem;
  height: 47.25875rem;
  background: #b46fff;
  border-radius: 80%;
  opacity: 0.3;
  filter: blur(200px);
}

.ellipse-yellow01 {
  left: 34%;
  bottom: 10%;
  width: 26.97325rem;
  height: 26.97325rem;
  border-radius: 26.97325rem;
  background: #ffe9b1;
  filter: blur(150px);
}

.ellipse-yellow02 {
  left: -2%;
  bottom: 30%;
  width: 17.13769rem;
  height: 17.13769rem;
  border-radius: 17.13769rem;
  background: #fcff64;
  filter: blur(150px);
}

.ellipse-purple02 {
  right: -25%;
  top: -25%;
  width: 80.60513rem;
  height: 62.15931rem;
  border-radius: 80.60513rem;
  background: #b8c5ff;
}

.ellipse-blue02 {
  right: -10%;
  bottom: -48%;
  width: 77.42594rem;
  height: 53.84713rem;
  border-radius: 77.42594rem;
  background: #3193f9;
}

.ellipse-orange01 {
  left: -30%;
  bottom: -75%;
  width: 101.53463rem;
  height: 101.634rem;
  border-radius: 101.634rem;
  background: #fead94;
  opacity: 0.4;
}

.ellipse-purple03 {
  left: -35%;
  top: 6%;
  width: 101.53463rem;
  height: 101.634rem;
  border-radius: 101.634rem;
  background: #b8c5ff;
}

@media (max-width: 1450px), (max-height: 760px) {
  .ellipse-purple01 {
    opacity: 0.125;
  }
  .ellipse-blue02 {
    opacity: 0.125;
  }
  .ellipse-orange01 {
    display: none;
  }
}

@media (max-width: 767.98px) {
  .ellipse-blue01 {
    display: none;
  }
  .ellipse-purple01 {
    left: -25%;
    bottom: 25%;
    width: 100vw;
    height: 100vh;
    filter: blur(100px);
  }
  .ellipse-yellow02 {
    opacity: 0.5;
  }
  .ellipse-purple02 {
    right: unset;
    left: -5%;
    top: -5%;
    width: 90vw;
    height: 100vw;
    border-radius: 100vw 100vw 20vw 20vw;
    opacity: 0.4;
  }
  .ellipse-blue02 {
    right: -20%;
    bottom: 10%;
    width: 100vw;
    height: 100vh;
    opacity: 0.25;
    border-radius: 100vw;
    filter: blur(200px);
  }
  .ellipse-orange01 {
    display: block;
    left: -10%;
    bottom: -12%;
    width: 100vw;
    height: 110vw;
    border-radius: 100%;
    opacity: 0.328;
    filter: blur(150px);
  }
  .ellipse-purple03 {
    display: none;
  }
} */

.nav__menu {
  display: inline-flex;
  gap: 0.45rem;
  margin-inline: auto;
  margin-block: 0;
  border-radius: 0.6rem;
  background: #222;
  padding: 0.5rem;
}
@media (max-width: 1199px) {
  .nav__menu {
    gap: 0;
  }
}
@media (max-width: 992px) {
  .nav__menu {
    flex-flow: column nowrap;
    margin: 3rem 0;
    width: 100%;
    gap: 1.4vw;
    padding: 1.5vw;
  }
}
.nav__menu .nav-link {
  width: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 2.8rem;
  overflow: hidden;
  border-radius: 0.3rem;
  border: 1px solid #292929;
  background: #3e3e3e;
  padding: 2px 0.65rem 0;
  transition: 0.1s ease-in-out;
}
@media (max-width: 1199px) {
  .nav__menu .nav-link {
    border: 0;
    padding: 2px 0.5rem 0;
    background: transparent;
  }
}
@media (max-width: 992px) {
  .nav__menu .nav-link {
    border-radius: 0.4rem;
    background: #3e3e3e;
    height: 3rem;
    border: 2px solid #292929;
    padding: 0.5rem 0.5rem 0.4rem;
  }
}
.nav__menu span {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 0.35rem;
  letter-spacing: 0.3px;
  font-size: 0.91rem;
  background: -webkit-linear-gradient(0deg, white, white);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.nav__menu span i {
  font-size: 1rem;
}
.nav__menu .active-link {
  background: transparent;
  border-color: transparent;
}
@media (max-width: 992px) {
  .nav__menu .active-link {
    border: 2px solid #3e3e3e;
  }
}
.nav__menu .active-link span {
  font-weight: 600;
  letter-spacing: 0;
  background: linear-gradient(
    36deg,
    #ffb6d5 0%,
    #ffe3c0 10%,
    #c7fbdb 29%,
    #c7cdf4 43%,
    #ffbcd4 54%,
    #ffe0c3 66%,
    #b0f1d4 76%,
    #c9ceec 84%,
    #ffc1da 91%,
    #bdffe3 100%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;
  width: 100vw;
  flex-direction: column;
  font-family: "Arial", sans-serif;
}

/* Home page starts here */
.button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  flex-direction: column;
  gap: 20px;
}

.button {
  width: 150px;
  padding: 15px 0;
  font-size: 1rem;
  font-weight: 600;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.button.auditor {
  background-color: #4caf50;
}

.button.auditor:hover {
  background-color: #228b22;
}

.button.distributor {
  background-color: #2196f3;
}

.button:hover {
  background-color: #333;
}

/* Responsive styling for smaller screens */
@media (max-width: 768px) {
  .button {
    width: 130px;
    font-size: 0.9rem;
  }
}

@media (max-width: 480px) {
  .button {
    width: 100%;
    max-width: 250px;
    font-size: 0.85rem;
  }
}
/* Home page ends here */

/* authentication section starts */
.login-form {
  background: linear-gradient(145deg, #ffffff, #f0f0f0);
  padding: 2rem;
  border-radius: 12px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  width: 100%;
  height: 100%;
  max-width: 400px;
  margin: 50px auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.login-title {
  font-size: 1.8rem;
  color: #333;
  margin-bottom: 1.5rem;
  font-weight: bold;
}

.input-group {
  display: flex;
  align-items: center;
  margin-bottom: 1.2rem;
  width: 100%;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 8px 12px;
  background-color: #f9f9f9;
}

.icon {
  color: #007bff;
  font-size: 1.5rem;
  margin-right: 10px;
}

.login-input {
  width: 100%;
  border: none;
  outline: none;
  font-size: 1rem;
  padding: 0.5rem;
  background: transparent;
  color: #555;
}

.login-input:focus {
  border: none;
  outline: none;
}

.login-button {
  width: 100%;
  margin: 0;
  padding: 0.8rem;
  margin-top: 1.2rem;
  border: none;
  border-radius: 8px;
  color: white;
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
  background-size: 100%;
  transition: all 0.3s ease-in-out;
  background: linear-gradient(135deg, #007bff, #0056b3);
}

.login-button:hover {
  /* background: linear-gradient(135deg, #0056b3, #003f7f); */
  background-size: 200%;
}

.error-message {
  color: red;
  margin-top: 1rem;
  font-size: 0.9rem;
}

.container {
  width: 100%;
  /* max-width: 400px; */
  margin: auto;
  padding: 0 1rem;
}

@media (max-width: 768px) {
  .login-form {
    width: 300px;
    margin-top: 20px;
  }
}
/* authentication section ends here */

/* distributor form  starts here */
.form-container {
  width: 100%;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.border-box {
  border: 1px solid #eaeaea;
  padding: 20px;
  border-radius: 8px;
  background-color: #f9f9f9;
  margin-bottom: 20px;
}

label {
  font-size: 12px;
  font-family: Arial, Helvetica, sans-serif;
  color: #4caf50;
}

button {
  margin-right: 10px;
}

.form-row {
  display: flex;
  flex-wrap: wrap; /* Allow wrapping on smaller screens */
  margin-bottom: 15px;
}

.form-group {
  padding: 5px 10px;
  flex: 1 1 calc(33.333% - 20px); /* Flex basis for three columns with margin */
  min-width: 150px;
}

@media (max-width: 768px) {
  .form-group {
    min-width: unset;
  }
}

input[type="file"] {
  padding: 2px;
}

.title-text {
  font-size: 1.1rem;
  font-weight: bold;
  color: blue;
  text-transform: uppercase;
  letter-spacing: 1px;
  background: linear-gradient(to right, #00b4d8, #0077b6);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  border-bottom: 2px solid #00b4d8;
  padding-bottom: 5px;
  margin: 10px 0;
}

/* ToggleBox.css */
.toggle-label {
  margin-left: 8px;
  margin-top: 1.8rem;
}

.toggle-box {
  width: 20px;
  height: 20px;
  border: 1px solid #333;
  margin-top: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  user-select: none;
  transition: background-color 0.2s ease;
}

.toggle-box:hover {
  background-color: #f0f0f0;
}

.toggle-box .checkmark {
  font-size: 1rem;
  color: #007bff;
}

.toggle-box.active {
  background-color: #007bff;
  border-color: #007bff;
}

.container-camera-capturer {
  margin-top: 20px;
  text-align: center;
}

.button {
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  margin: 10px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
}

.video {
  width: 300px;
  border-radius: 10px;
}

.capture-button {
  position: absolute;
  bottom: 10px;
  left: 20px;
  padding: 10px;
  background-color: #28a745;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.close-button {
  position: absolute;
  bottom: 10px;
  right: 20px;
  padding: 10px;
  background-color: #dc3545;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.gallery {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 20px;
}

.thumbnail-container {
  position: relative;
  margin: 10px;
}

.thumbnail {
  width: 100px;
  height: 100px;
  border-radius: 10px;
  object-fit: cover;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.delete-button {
  position: absolute;
  top: 5px;
  right: 5px;
  background-color: #ff4d4d;
  color: #fff;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  width: 25px;
  height: 25px;
  text-align: center;
  line-height: 20px;
}

@media (max-width: 991.98px) {
  .form-group {
    flex: 1 1 calc(50% - 20px);
  }
  .title-text {
    font-size: 0.9rem;
  }
}

@media (max-width: 767.98px) {
  .form-group {
    flex: 1 1 calc(50% - 20px);
  }
  .title-text {
    font-size: 0.9rem;
  }
}
/* distributor form styles for form rows ends here */

/* landing page starts here */
.sidebar {
  min-height: 100vh;
  padding: 20px;
  background-color: #228b22;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
}

.sidebar-title {
  font-size: 1.5rem;
  margin-bottom: 20px;
  color: #333;
}

.sidebar-link {
  margin: 10px 0;
  padding: 10px 15px;
  border-radius: 5px;
  transition: background-color 0.3s, color 0.3s;
}

.sidebar-link:hover {
  background-color: #007bff;
  color: white;
}

.sidebar-link.active {
  background-color: #007bff;
  color: white !important;
}

@media (max-width: 768px) {
  .sidebar {
    min-height: 40vh;
  }
}
@media (max-width: 991.98px) {
  .sidebar {
    min-height: 100vh;
  }
}
@media (max-width: 1199.98px) {
  .sidebar {
    min-height: 40vh;
  }
}
.table-container {
  overflow-x: auto;
  overflow-y: auto;
  max-height: 80vh;
  margin: 20px;
}
table {
  width: 100%;
  border-collapse: collapse;
}
th,
td {
  padding: 12px;
  border: 1px solid #ddd;
  text-align: left;
}
tbody td {
  text-align: left;
}

th {
  background-color: #f4f4f4;
  font-weight: bold;
}

td {
  background-color: #ffffff;
}

.clickable {
  background: none;
  border: none;
  color: #007bff;
  text-decoration: underline;
  cursor: pointer;
}

.clickable:hover {
  color: #0056b3;
}

@media (max-width: 768px) {
  .table-container {
    max-height: 60vh;
  }

  th,
  td {
    padding: 8px;
    font-size: 14px;
  }
}

.filter-container {
  margin-bottom: 10px;
  display: flex;
  justify-content: flex-start;
}

.filter-container input[type="text"] {
  padding: 7px;
  border: 1px solid #ccc;
  border-radius: 5px;
  width: 250px;
  transition: border-color 0.3s;
}

input[type="text"]:focus {
  border-color: #007bff;
  outline: none;
}

@media (max-width: 768px) {
  th,
  td {
    padding: 8px;
    font-size: 14px;
  }
}

/* report pagination starts here */
.pagination-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
}

.pagination-info {
  font-weight: bold;
}

.pagination {
  display: flex;
  align-items: center;
}

.arrow-button,
.page-button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 5px 10px;
  margin: 0 5px;
  cursor: pointer;
  border-radius: 3px;
  transition: background-color 0.3s;
}

.arrow-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.page-button {
  min-width: 30px; /* Ensure buttons have a uniform size */
}

.page-button.active {
  background-color: #0056b3;
}

.arrow-button:hover,
.page-button:hover {
  background-color: #0056b3;
}

/* table component  */
.report-table-details {
  width: 100%;
  margin: 5px auto;
  overflow-x: auto;
}

.report-table-detailstable {
  width: 100%;
  border-collapse: collapse;
  text-align: left;
}

.report-table-details th,
.report-table-details td {
  border: 1px solid #ddd;
  padding: 8px;
}

.report-table-details th {
  background-color: #f4f4f4;
  color: #333;
}

.report-table-details tbody tr:hover {
  background-color: #f9f9f9;
}

.report-table-details .back-arrow {
  font-size: 24px;
  cursor: pointer;
  margin-right: 10px;
  color: #2196f3;
}

.report-table-details .back-arrow:hover {
  color: #007bff;
}

@media (max-width: 768px) {
  th,
  td {
    font-size: 14px;
    padding: 8px;
    height: auto;
  }
}
@media (min-width: 769px) and (max-width: 991.98px) {
  th,
  td {
    font-size: 14px;
    padding: 8px;
    height: auto;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  th,
  td {
    font-size: 14px;
    padding: 8px;
    height: auto;
  }
}

/* Header styling */
/* .nav-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
} */
.navbar-collapse {
  display: flex;
  align-items: center;
  justify-content: center;
  /* padding-left: 3.5rem; */
}
.navbar-nav .nav-link {
  opacity: 0.6;
  font-size: 0.875rem;
  padding: 0.6rem 0.75rem 0.5rem;
  transition: all 0.3s ease-in-out;
}
.navbar-nav .nav-link:hover,
.navbar-nav .active-link {
  opacity: 1;
}
.custom-navbar {
  min-height: 6.5rem;
}
.custom-navbar .navbar-brand {
  margin: -0.75rem 0 -0.5rem;
  padding: 0;
}

.navbar-nav {
  margin-left: auto;
  padding-left: 4.5rem;
}

.navbar-brand img {
  max-width: 3.5rem;
}
/* logout  */
.logout-btn {
  padding: 0;
  margin: 0;
  width: 2rem;
  height: 2rem;
  flex-shrink: 0;
  background-color: rgb(253, 103, 103);
  color: white;
  border: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.35rem;
  border-radius: 5px;
  margin-left: 0.65rem;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.logout-btn:hover {
  background-color: red;
  /* transform: translateY(-50%) translateY(0px); */
}

.navbar-brand-name {
  /* margin-left: auto; */
  font-size: 2rem;
  color: black;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
  animation: shine 2s infinite;
}
.custom-navbar .nav-top-row {
  display: none;
}

@media (max-width: 1024px) {
  .navbar-nav {
    padding-left: 2.5rem;
  }
  .logout-btn {
    margin-left: 0.4rem;
  }
}
@media (max-width: 992px) {
  /* .nav-wrapper {
    justify-content: space-between;
  } */
  .navbar-toggler {
    margin: 0;
  }
  .custom-navbar {
    position: static;
  }
  .navbar-collapse#navbar-nav {
    margin: 0;
    position: absolute;
    left: -90%;
    top: 0;
    bottom: 0;
    z-index: 999;
    transition: left 0.5s ease-out !important;
    animation: none !important;
    max-width: 18rem;
    height: 100vh;
    background: #fefefe;
    box-shadow: 2px 0 15px rgba(255, 255, 255, 0.4);
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 3rem 1.8rem 3rem;
  }
  .navbar-collapse#navbar-nav.show {
    left: 0;
  }
  .overlay {
    position: absolute;
    inset: 0;
    z-index: 998;
    background-color: rgba(0, 0, 0, 0.65);
    display: none;
  }
  .navbar-collapse#navbar-nav.show + .overlay {
    display: block;
  }
  .logout-btn,
  .navbar-nav,
  .navbar-brand-name {
    margin: 0;
    padding: 0;
  }
  .logout-btn {
    width: auto;
    height: 2.2rem;
    font-size: 15px;
    padding: 0 0.75rem;
  }
  .navbar-nav .nav-link {
    padding-inline: 0;
  }
  .navbar-brand-name {
    margin-top: auto;
    padding-block: 1rem 1rem;
  }

  .navbar-collapse#navbar-nav.show .nav-top-row {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .navbar-collapse#navbar-nav.show .navbar-brand {
    margin: -0.5rem auto -0.5rem;
  }

  .navbar-collapse#navbar-nav.show .close-btn {
    width: 1.5rem;
    height: 1.5rem;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background: transparent;
    border: none;
    position: absolute;
    top: 1.35rem;
    right: 1.75rem;
    cursor: pointer;
    padding: 0.25rem;
    transition: all 0.3s ease-in-out;
    display: flex;
    margin-right: 0;
  }
  .navbar-collapse#navbar-nav.show .close-btn i {
    font-size: 1.25rem;
  }
  .navbar-collapse#navbar-nav.show .close-btn:hover,
  .navbar-collapse#navbar-nav.show .close-btn:focus,
  .navbar-collapse#navbar-nav.show .close-btn:active {
    color: #ff6e6e;
  }
}
.count-tbl-dwnload {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  margin-bottom: 1rem;
}
@media (max-width: 2200px) {
  .container {
    max-width: 90%;
  }
  .custom-navbar .navbar-brand {
    min-width: 7rem;
  }
}
@media (max-width: 1366px) {
  .container {
    max-width: 95%;
  }
  .custom-navbar .navbar-brand {
    min-width: 5rem;
  }
}
@media (max-width: 768px) {
  .container {
    max-width: 98.5%;
  }
  .custom-navbar .navbar-brand {
    min-width: unset;
  }
}
