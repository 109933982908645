
/* Override the default Toastify styles to reduce the text size */
.Toastify__toast {
  font-size: 13px !important;
}
  .upload-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 50px;
    background: #ffffff;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
   .upload-container .container {
    display: flex;
    flex-flow: column nowrap;
    /* margin-top: 1rem; */
    min-height: calc(100vh - 15rem);
  }
  
  .file-input {
    display: none;
  }
  
  .upload-label {
    display: inline-block;
    margin-bottom: 10px;
  }
  
  .file-button {
    display: inline-block;
    padding: 10px 20px;
    background-color: #0056b3;
    color: #ffffff;
    font-size: 16px;
    font-weight: 500;
    border-radius: 5px;
    cursor: pointer;
    text-align: center;
    transition: background-color 0.3s ease;
  }
  
  .file-button:hover {
    background-color: #2882e2;
  }
  
  .submit-button {
    padding: 10px 20px;
    background-color: rgb(28, 126, 28);
    color: #ffffff;
    font-size: 16px;
    font-weight: 500;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .submit-button:hover {
    background-color: rgb(55, 150, 55);
  }

  .upload-form {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  
  /* download */
 
.download-container {
  padding: 20px;
  background-color: #f4f4f9;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  width: 80%;
  margin: 20px auto;
}

.download-container h2 {
  text-align: center;
  font-size: 1.5rem;
  color: #333;
}

.download-container select,
.download-container button {
  width: 100%;
  padding: 7px;
  margin: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1rem;
  background-color: #fff;
  transition: background-color 0.3s ease;
}

.download-container select:focus,
.download-container button:focus {
  outline: none;
  border-color: #007bff;
}

.download-container button {
  background-color: #007bff;
  color: #fff;
  font-weight: bold;
}

.download-container button:disabled {
  background-color: #b0b0b0;
  cursor: not-allowed;
}

.download-container button:hover:not(:disabled) {
  background-color: #0056b3;
}

label {
  font-size: 1rem;
  color: #555;
}

.select-container {
  margin-bottom: 20px;
}

button {
  cursor: pointer;
}

button + button {
  margin-top: 10px;
}
