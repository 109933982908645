.var-upload-container {
  padding: 1rem 0 0;
}
.var-upload-container h2 {
  margin: 0;
  color: #444;
  font-weight: 600;
  font-size: 1.2rem;
}

.var-upload-container label {
  font-weight: bold;
  margin-right: 10px;
  margin-top: 10px;
}

.var-upload-file {
  display: flex;
  gap: 0.5rem;
  flex-flow: row wrap;
  margin: 1.2rem 0 1.2rem;
  align-items: center;
}

.var-filter-container {
  display: flex;
  align-items: center;
  flex-flow: row wrap;
  gap: 0.8rem;
  margin-bottom: 1rem;
}
.var-filter-container > .v-filter {
  max-width: calc(25% - (0.8rem * 3) / 4);
  width: 100%;
}

@media (max-width: 992px) {
  .var-filter-container > .v-filter {
    max-width: calc(50% - (0.8rem * 3) / 4);
  }
}
@media (max-width: 460px) {
  .var-filter-container > .v-filter {
    max-width: 100%;
  }
}

/* .var-upload-container .rdt_Table .rdt_TableHead .rdt_TableCol:nth-child(5),
.var-upload-container .rdt_Table .rdt_TableBody .rdt_TableCell:nth-child(5) {
  min-width: 18rem;
} */
.var-upload-container .rdt_Table .rdt_TableBody .rdt_TableCell:nth-child(2),
.var-upload-container .rdt_Table .rdt_TableBody .rdt_TableCell:nth-child(3) {
  color: #000;
}
.var-upload-container .rdt_Table .rdt_TableHead .rdt_TableCol:nth-child(6),
.var-upload-container .rdt_Table .rdt_TableBody .rdt_TableCell:nth-child(6) {
  min-width: 20rem;
}

.rdt_Table .rdt_TableHead .rdt_TableCol div > div{
  /* padding-left: 0.8rem; */
}

.var-table table {
  margin: 20px auto;
  border-collapse: collapse;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  background-color: white;
}

.var-table table th,
table td {
  padding: 10px;
  text-align: left;
  border: 1px solid #ddd;
}

.var-table table tbody tr:nth-child(even) {
  background-color: #f2f2f2;
}

.var-table table tbody tr:hover {
  background-color: #f5f5f5;
}

.var-text {
  text-align: center;
  font-size: 18px;
  color: #666;
}

.var-heading-inside {
  /* color: #989898; */
  color: orange;
  margin-bottom: 2px;
  min-width: 6em;
  font-size: 12px !important;
}

.container-variance .rdt_Pagination {
  border-top: 0;
  font-size: 13px;
  font-weight: 500;
  padding-inline: 0;
  min-height: unset;
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
}
.container-variance .rdt_Pagination .bpcekY {
  border-radius: 3px;
  border: 1.5px solid #e1e1e1;
}
.container-variance .rdt_Pagination .jEvKKB {
  height: 2rem;
  padding-top: 2px;
  order: 1;
  margin-inline: 1rem 0;
  display: inline-flex;
  align-items: center;
}
.container-variance .rdt_Pagination .eLgGMP {
  margin-inline: auto;
  gap: 0.5rem;
}
@media (max-width: 599px) {
  .container-variance .rdt_Pagination .eLgGMP {
    justify-content: center;
  }
  .container-variance .rdt_Pagination .eLgGMP button {
    order: 1;
  }
  .container-variance .rdt_Pagination .bpcekY {
    margin-right: auto;
  }
}
.container-variance .rdt_Pagination .eLgGMP button {
  margin: 0;
  padding: 0;
  width: 2.2rem;
  height: 2.2rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 0.875rem;
  border: 1px solid #e1e1e1;
}
