@import url("https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght@200..800&display=swap");

.ff-plus-jakarta {
  font-family: "Plus Jakarta Sans", serif;
}

.page-title {
  margin: 0;
  font-size: 1.125rem;
  font-weight: 600;
  display: inline-flex;
  align-items: center;
  height: 100%;
}

.rdt_Table {
  font-size: 14px;
  margin-bottom: 0.5rem;
}
.rdt_Table .rdt_TableHead .rdt_TableCol div,
.rdt_Table .rdt_TableBody .rdt_TableCell div:not(.btn) {
  white-space: normal;
  font-size: 13px;
}

.rdt_Table .rdt_TableHead .rdt_TableCol[data-column-id="4"],
.rdt_Table .rdt_TableBody .rdt_TableCell[data-column-id="4"] {
  min-width: 8rem;
}
.rdt_Table .rdt_TableHead .rdt_TableCol[data-column-id="1"],
.rdt_Table .rdt_TableBody .rdt_TableCell[data-column-id="1"] {
  min-width: 3.5rem;
}

.rdt_Table .rdt_TableHead .rdt_TableCol[data-column-id="7"],
.rdt_Table .rdt_TableBody .rdt_TableCell[data-column-id="7"] {
  display: flex;
  min-width: 6.5rem;
}
.rdt_Table .rdt_TableHead .rdt_TableCol[data-column-id="43"],
.rdt_Table .rdt_TableBody .rdt_TableCell[data-column-id="43"] {
  width: 3.5rem;
  min-width: unset;
}

.rdt_Table .rdt_TableHead .rdt_TableCol,
.table thead th {
  font-size: 14px;
  font-weight: 600;
  box-shadow: none;
  padding: 1.35rem 0.35rem;
  vertical-align: middle;
  background-color: #f8f9fb;
  border: 0;
  border-top: 1.5px solid #e1e1e1;
}
.rdt_Table .rdt_TableBody .rdt_TableCell,
.table tbody td {
  border: 0;
  font-size: 14px;
  padding: 0.5rem 0.35rem;
  vertical-align: middle;
}
.rdt_Table .greenText,
.table .greenText {
  color: #1b8650;
  font-weight: 500;
}
.rdt_Table .rdt_TableHead .rdt_TableCol:first-child,
.table thead th:first-child {
  border-left: 1.5px solid #e1e1e1;
  border-radius: 10px 0 0 0;
  padding-right: 0;
  width: 5%;
}
.rdt_Table .rdt_TableHead .rdt_TableCol:last-child,
.table thead th:last-child {
  border-right: 1.5px solid #e1e1e1;
  border-radius: 0 10px 0 0;
  min-width: 10rem;
  width: 5%;
}
.rdt_Table .rdt_TableBody .rdt_TableCell:first-child,
.table tbody td:first-child {
  border-left: 1.5px solid #e1e1e1;
  padding-right: 0;
}
.rdt_Table .rdt_TableBody .rdt_TableCell:last-child,
.table tbody td:last-child {
  border-right: 1.5px solid #e1e1e1;
  min-width: 10rem;
}
.rdt_Table .rdt_TableBody .rdt_TableRow:last-child .rdt_TableCell,
.table tbody tr:last-child td {
  border-bottom: 1.5px solid #e1e1e1;
}
.rdt_Table .rdt_TableBody .rdt_TableRow:last-child .rdt_TableCell:first-child,
.table tbody tr:last-child td:first-child {
  border-radius: 0 0 0 10px;
}
.rdt_Table .rdt_TableBody .rdt_TableRow:last-child .rdt_TableCell:last-child,
.table tbody tr:last-child td:last-child {
  border-radius: 0 0 10px 0;
}
.rdt_Table .rdt_TableBody .rdt_TableCell:nth-child(2) {
  font-weight: 500;
  color: #1b8650;
}
.rdt_Table .rdt_TableBody .rdt_TableCell:nth-child(3) {
  color: #a4a4a4;
}
.table thead th,
.table tbody td {
  border-bottom: 1.5px solid #e1e1e1;
}
.confirm-cover {
  display: flex;
  align-items: center;
  gap: 0.4rem;
}
.confirm-cover .textArea {
  flex: 1;
  max-width: 5.5rem;
  height: 2.5rem;
  font-size: 12px;
  min-width: unset;
  border-radius: 0.25rem;
  padding: 0.2rem 0.5rem;
}
.confirm-cover .textArea:focus {
  outline: 0;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.1);
}
.tbl-cancel-btn,
.tbl-success-btn {
  padding: 0;
  width: 1.5rem;
  height: 1.5rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: 1.5px solid #f44646;
}
.tbl-success-btn {
  margin-top: 0;
  border: 1.5px solid #3a9e2c;
}
.tbl-cancel-btn i {
  font-size: 1.25rem;
  color: #f44646;
  transition: 0.3s ease-in-out;
}
.tbl-success-btn i {
  font-size: 1.25rem;
  color: #3a9e2c;
  transition: 0.3s ease-in-out;
}
.tbl-cancel-btn:hover,
.tbl-cancel-btn:focus,
.tbl-cancel-btn:active {
  border: 1.5px solid #f44646;
}
.tbl-cancel-btn:hover i,
.tbl-cancel-btn:focus i,
.tbl-cancel-btn:active i {
  font-size: 1.35rem;
  color: #d93030;
}
.tbl-success-btn:hover,
.tbl-success-btn:focus,
.tbl-success-btn:active {
  border: 1.5px solid #249016;
}
.tbl-success-btn:hover i,
.tbl-success-btn:focus i,
.tbl-success-btn:active i {
  font-size: 1.35rem;
  color: #249016;
}
.reports-wrapper .rdt_Pagination {
  margin-top: 0.65rem;
  border-top: 0;
  font-size: 13px;
  font-weight: 500;
  padding-inline: 0;
  min-height: unset;
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
}
.reports-wrapper .rdt_Pagination .bpcekY {
  border-radius: 3px;
  border: 1.5px solid #e1e1e1;
}
.reports-wrapper .rdt_Pagination .jEvKKB {
  height: 2rem;
  padding-top: 2px;
  order: 1;
  margin-inline: 1rem 0;
  display: inline-flex;
  align-items: center;
}

.action-btn-group {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 0.75rem;
}

.action-btn-group .btn,
.btn-download button {
  padding: 0.5rem;
  border: 0;
  height: 42px;
  gap: 0.35rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: #f7f7f7;
  letter-spacing: 0.05rem;
  border-radius: 7px;
  color: #323232;
  transition: 0.3s ease-in-out;
}

.btn-download-outer button {
  border: 0;
  padding: 0.5rem 0.8rem;
  height: 42px;
  aspect-ratio: 1;
  gap: 0.35rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: #f7f7f7;
  border-radius: 7px;
  color: #323232;
  transition: 0.3s ease-in-out;
  margin: 0;
}
.btn-download-outer button:hover,
.btn-download-outer button:focus,
.btn-download-outer button:focus-within,
.btn-download-outer button:active,
.btn-download-outer button.active {
  outline: 0;
  box-shadow: none;
  color: #323232 !important;
  background: #ece9e9 !important;
}

.btn-view {
  cursor: pointer;
  font-size: 13px;
  padding: 0 1rem;
  padding: 6px 12px;
  white-space: nowrap;
}

.dropdown-toggle::after {
  display: none;
}

.action-btn-group .btn i {
  font-size: 1rem;
}

.btn-download,
.btn-download button {
  padding: 0;
  width: 44px;
}

.top-header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
  gap: 0.75rem;
}
.top-header__right {
  display: inline-flex;
  align-items: center;
  justify-content: flex-end;
}

.btn-view:hover,
.btn-view:focus,
.btn-view:focus-within,
.btn-view:active,
.btn-download:hover,
.btn-download:focus,
.btn-download:focus-within,
.btn-download:active,
.btn-download button:hover,
.btn-download button:focus,
.btn-download button:focus-within,
.btn-download button:active {
  outline: 0;
  color: #323232 !important;
  background-color: #ece9e9 !important;
}

.form-group--search {
  max-width: 60%;
  margin-left: auto;
  position: relative;
}

.form-group--search .form-control {
  height: 2.5rem;
  font-size: 1rem;
  padding: 0 0 0.12rem;
  padding-inline: 0.85rem 2rem;
  transition: 0.3s ease-in-out;
  border: 1.5px solid #e1e1e1;
}

.form-group--search .form-control:focus {
  outline: 0;
  border-color: #aeadad;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.15);
}

.form-group--search .form-control::placeholder {
  color: #aeadad;
  font-size: 13px;
}

.form-group--search .form-control + i {
  transition: 0.3s ease-in-out;
  position: absolute;
  right: 0.8rem;
  top: 0.55rem;
  color: #aeadad;
}
.form-group--search .form-control:focus + i {
  color: #2f2f2f;
}

/* day report table */

/* Image container */
.image-container {
  position: relative;
  overflow: hidden;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Close icon in the top-right corner */
.close-icon {
  position: absolute;
  top: 5px;
  right: 5px;
  background: transparent;
  border: none;
  color: red;
  font-size: 24px;
  cursor: pointer;
  z-index: 10;
  transition: color 0.3s ease;
}

.close-icon:hover {
  color: #d9534f;
}

.signature-img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
  transition: transform 0.3s ease-in-out;
}

.signature-img:hover {
  transform: scale(1.05);
}

.filter-button,
.dwn-button {
  background: #323232;
  color: white;
  border: none;
  height: 2.4rem;
  font-weight: 500;
  font-size: 0.938rem;
  border-radius: 4px;
  padding: 0.25rem 0.85rem;
  display: inline-flex;
  align-items: center;
  white-space: nowrap;
  gap: 0.25rem;
  transition: all 0.3s ease-in-out;
}
.filter-button:hover,
.filter-button:focus,
.filter-button:active,
.dwn-button:hover,
.dwn-button:focus,
.dwn-button:active {
  background: #323232;
  color: white;
  opacity: 0.88;
}
.dwn-button i {
  line-height: 1;
}
.dropdown-content {
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  min-width: 19rem;
  position: absolute;
  background: white;
  border: 1px solid #ededed;
  margin-top: 5px;
  padding: 0;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}

.acc-header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.5rem;
  border: 0;
  line-height: 1;
  background-color: transparent;
}

.dropdown-content label,
.filter-button span {
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  font-weight: 500;
  font-size: 0.9rem;
}

.dropdown-content .dd-item {
  padding: 0.85rem 1rem 0.75rem;
  border-bottom: 1px solid #ededed;
}
.dropdown-content .dd-item:last-child {
  border-bottom: 0;
}
.dd-item .form-control {
  font-size: 0.875rem;
  height: 2.4rem;
}
.dd-item .form-control:focus {
  outline: 0;
  border-color: #aeadad;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.15);
}

/* Make the table allow dynamic resizing */
table {
  table-layout: auto; /* Allow columns to resize based on content */
  width: 100%;
}

.action-btn-group {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.imageList-container {
  display: flex;
  flex-wrap: wrap;
  /* gap: 5px; */
}

.images-img {
  /* width: auto; */
  /* max-width: 100%; */
  /* height: auto; */
  /* max-height: 70px; Adjust as needed */
  border-radius: 5px;
}

.close-icon {
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
}

@media (max-width: 768px) {
  /* Adjust layout for smaller screens */
  .imageList-container {
    flex-direction: column;
  }
}

.column-set {
  display: flex;
  flex-flow: column nowrap;
  gap: 1rem;
}

.filter-row {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.date-picker-wrapper {
  position: relative;
}

.react-datepicker-wrapper {
  min-width: 15rem;
}

.date-picker-wrapper i {
  position: absolute;
  top: 0.5rem;
  left: 0.75rem;
  color: #969696;
  transition: all 0.3s ease-in-out;
}
.date-picker-wrapper:has(input:focus) i {
  color: #5b5b5b;
}
.react-datepicker__input-container input {
  width: 100%;
  height: 2.5rem;
  border-radius: 5px;
  font-size: 0.875rem;
  border: 1.5px solid #e1e1e1;
  padding: 0 16px 0 2.125rem;
  transition: all 0.3s ease-in-out;
}
.react-datepicker__input-container input:focus {
  outline: 0;
  border-color: #aeadad;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.15);
}

.radio-selections {
  display: flex;
  align-items: center;
  gap: 0.85rem;
  margin-top: 0.45rem;
}
.form-check .form-check-input {
  margin-left: -1.35rem;
  margin-top: 0.3rem;
}
.form-check label {
  line-height: 1;
  font-size: 14px;
}
.form-check {
  margin-bottom: 0;
}

.preview {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  gap: 0.5rem;
}

.toast-div {
  max-width: fit-content;
  box-shadow: none;
}

.toast-div .toast-header {
  border-bottom: 0;
  border-radius: 7px;
}
.toast-div .toast-header .btn-close {
  margin-left: 0.5rem;
  background-size: 0.8rem;
}
.tag-label {
  font-size: 13px;
}

/* counts css */
/* YourComponent.css */

.count-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  font-size: 15px;
  font-family: Arial, sans-serif;
  color: #333;
  margin-bottom: 10px;
  margin-top: 5px;
  width: 100%;
}

.label {
  /* font-weight: bold; */
  margin-right: 5px;
}

.value {
  margin-right: 15px; /* Space between values */
  font-weight: normal;
  color: #555;
}

textarea {
  font-size: 8px;
  min-height: 20px;
  height: unset;
  appearance: none;
  resize: none;
  min-width: 120px;
}
.textArea-icon {
  position: "absolute";
  margin-left: "105px";
  bottom: "52px";
}
.date-custom + span {
  opacity: 0.5;
}
.miw-3 {
  min-width: 3rem;
}
.miw-5 {
  min-width: 5rem;
}
.rdt_Table .rdt_TableHead .rdt_TableCol[data-column-id="1"],
.rdt_Table .rdt_TableBody .rdt_TableCell[data-column-id="1"] {
  min-width: unset;
}

.report-header {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}
.filter-report-row {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  margin-bottom: 1rem;
}

@media (max-width: 768px) {
  .filter-row {
    flex-flow: row wrap;
    gap: 0.65rem;
  }

  .dwn-button {
    margin: 0 !important;
  }
  .react-datepicker-wrapper {
    min-width: 12rem;
  }
}
@media (max-width: 768px) {
  .top-header {
    flex-flow: row wrap;
  }
  .top-header .react-datepicker-wrapper {
    min-width: unset;
  }
  .top-header .react-datepicker-wrapper input {
    font-size: 0.813rem;
  }
}
