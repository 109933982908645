@import url("https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght@200..800&display=swap");

.form-container {
  display: flex;
  flex-flow: column nowrap;
  min-height: 100vh;
  background: rgba(248, 249, 251, 1);
  font-family: "Plus Jakarta Sans", sans-serif;
}
.client-section {
  display: flex;
  justify-content: space-between;
  padding: 20px;
  background: #fff;
  border-bottom: 1px solid rgba(224, 224, 224, 1);
}

.client-section-first {
  flex: 1;
  margin-right: 20px;

  display: inline-flex;
  flex-flow: column nowrap;
  justify-content: center;
  gap: 0.35rem;
  margin-top: 4px;
}

.client-section-first h6 {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 0;
}

.client-section-first h5 {
  font-size: 12px;
  font-weight: 400;
  color: rgba(174, 173, 173, 1);
}

.client-select-second {
  display: flex;
  align-items: center;
  min-width: 20rem;
}
.client-select-second > div {
  width: 100%;
}
.form-physical-stock {
  padding: 30px 25px;
  flex: 1;
}
.physical-stock-heading {
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 25px;
}

.physical-stock-sheet-outer,
.photos-upload-section,
.custom-field-section {
  border-radius: 10px;
  width: 100%;
  margin-top: 1rem;
  overflow: hidden;
  border: 1.5px solid #e0e0e0;
}

.physical-stock-header,
.custom-field-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.2rem 1.5rem;
  background-color: #fff;
  transition: all 0.3s ease-in-out;
}
.physical-stock-header:has(+ .physical-stock-main-content),
.custom-field-header:has(+ .form-row) {
  padding: 1.8rem 1.5rem;
  background-color: #f8f9fb;
}
.physical-stock-header h2,
.custom-field-header h2 {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 0;
}
.icon-dropdown-first,
.icon-dropdown-second {
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: flex-end;
  min-width: 5rem;
}
.physical-stock-main-content {
  background: #fff;
  padding: 1.75rem 1.5rem;
  border-radius: 0 0 10px 10px;
}

.form-group1-description label {
  font-size: 12px;
  text-transform: uppercase;
}

.form-line {
  width: 100%;
  opacity: 1;
  margin: 3rem 0 2.35rem;
  border-color: #eeeeee;
}

/* upload photo section */

.photos-upload-header {
  display: flex;
  justify-content: space-between;
  padding: 0.8rem 1.5rem;
  background-color: #fff;
  transition: all 0.3s ease-in-out;
}
.photos-upload-header:has(+ .photos-upload) {
  padding: 1.35rem 1.5rem;
  background-color: #f8f9fb;
}

.photos-upload-header h3 {
  font-family: "Plus Jakarta Sans", sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  color: #000;
  margin: 0;
}

.photos-upload-header h2 {
  font-family: "Plus Jakarta Sans", sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 15px;
  color: rgba(174, 173, 173, 1);
  margin: 0;
}

.photos-upload {
  display: flex;
  flex-direction: column;
  gap: 25px;
  background-color: #fff;
  padding: 25px 1.5rem;
}

.upload-photo,
.open-camera {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  width: 48%;
  border-radius: 7px;
  border: 1.5px dashed;
}

.upload-photo {
  border-color: rgba(63, 109, 202, 1);
  background-color: rgba(248, 249, 251, 1);
}

.open-camera {
  border-color: rgba(27, 134, 80, 1);
  background-color: rgba(248, 251, 249, 1);
}

.upload-photo-text,
.open-camera-text {
  display: flex;
  flex-direction: column;
  gap: 5px;
  font-family: "Plus Jakarta Sans", sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 15px;
  color: rgba(116, 116, 116, 1);
}

.upload-photo-text h2,
.open-camera-text h2 {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 0;
  color: #323232;
}

.upload-photo-image {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 42px;
  height: 42px;
  border-radius: 50%;
  background-color: #ffffff;
  border: 1.5px solid rgba(224, 224, 224, 1);
}

.upload-photo-image i,
.open-camera-image i {
  font-size: 20px;
  color: rgba(116, 116, 116, 1);
}

.preview-photo-container {
  position: relative;
  width: 7.6rem;
  /* width: calc(20% - 10px); */
}

.preview-photo {
  width: 100%;
  height: 100px;
  object-fit: cover;
}

.container-camera-capture {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.video-container {
  position: relative;
  width: 100%;
  max-width: 300px;
  text-align: center;
}

.video {
  width: 100%;
  border: 2px solid #ddd;
  border-radius: 8px;
}

.capture-button,
.close-button {
  padding: 10px 18px;
  border: none;
  cursor: pointer;
  border-radius: 25px;
  background-color: #007bff;
  color: white;
}

.close-button {
  background-color: #f44336;
}

/* Custom field section */

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 5px;
  background-color: white;
}

.icon-dropdown-third {
  cursor: pointer;
}
.custom-field-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 40px;
  gap: 5px; /* Adjusted for better spacing between icon and text */
  border-radius: 5px;
  background-color: rgba(50, 50, 50, 1);
  cursor: pointer;
  margin: 10px 10px 0px 0px;
}

.custom-field-btn span {
  font-family: DM Sans;
  font-size: 14px;
  font-weight: 500;
  color: rgba(255, 255, 255, 1);
  text-align: center;
}
.form-footer {
  width: 100%;
  height: 104px;
  padding: 20px 1.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: rgba(255, 255, 255, 1);
  box-shadow: 0px -3px 40px rgba(0, 0, 0, 0.1);
}

.form-footer-content {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.auto-saved {
  display: flex;
  align-items: center;
  gap: 0.4rem;
}
.auto-saved i {
  color: #1b8650;
  font-size: 1.2rem;
}
.auto-saved span {
  font-size: 16px;
  font-weight: 600;
  color: #323232;
}
.form-footer-btn {
  display: flex;
  gap: 10px;
  opacity: 1;
}

.footer-btn {
  height: 54px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0 20px;
  border-radius: 5px;
  gap: 0.5rem;
  font-size: 1rem;
  min-width: 8rem;
  transition: all 0.3s ease-in-out;
}

.download-btn {
  color: #1b8650;
  background-color: rgba(243, 255, 250, 1);
  border: 1px solid rgba(24, 135, 84, 1);
  margin-top: 10px;
}
.download-btn:hover,
.download-btn:focus,
.download-btn:active {
  opacity: 0.8;
  color: #1b8650;
  background-color: rgba(243, 255, 250, 1);
  border: 1px solid rgba(24, 135, 84, 1);
}

.submit-btn {
  border: 1px solid rgba(24, 135, 84, 1);
  background-color: rgba(24, 135, 84, 1);
  color: #ffffff;
}
.submit-btn:hover,
.submit-btn:focus,
.submit-btn:active {
  opacity: 0.8;
  border: 1px solid rgba(24, 135, 84, 1);
  background-color: rgba(24, 135, 84, 1);
  color: #ffffff;
}
.nsi-checkbox {
  margin-bottom: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
}
.nsi-checkbox label {
  font-size: 14px;
}

.nsi-checkbox .toggle-box {
  border-radius: 4px;
}
.nsi-checkbox .toggle-label label {
  color: #525252;
}
.nsi-checkbox::after {
  position: absolute;
  content: "";
  border: 1px solid #eee;
  top: 0.85rem;
  left: 8.25rem;
  right: 2.5rem;
}

.form-container .row {
  gap: 1.5rem 0;
}
.form-container .row label {
  margin-bottom: 0.35rem;
  font-size: 12px;
  font-weight: 600;
  color: #525252;
  transition: all 0.3s ease-in-out;
}
.form-container .row .form-control,
[class^="css-"][class$="-control"] {
  font-size: 12px;
  min-height: 40px;
}
textarea.form-control {
  padding-top: 12px;
}
.form-container .row .form-control::placeholder {
  color: #aeadad;
}
.form-container .row label:has(+ .form-control:focus),
.form-container
  .row
  label:has(
    + [class^="css-"][class$="-container"] > [class^="css-"][class$="-menu"]
  ) {
  color: #1b8650;
}
.form-container .table .form-select,
.form-container .table .form-control {
  border: 0;
}
.form-container .form-control:focus,
.form-container .form-select:focus,
.form-container .row .form-control:focus,
[class^="css-"][class$="-control"]:hover,
[class^="css-"][class$="-control"]:has(+ [class^="css-"][class$="-menu"]),
[class^="css-"][class$="-control"]:focus {
  outline: 0;
  border: 1px solid #1b8650;
  box-shadow: 0px 0px 0px 3px #1b86501a;
}

.table th,
.table td {
  padding: 4px;
  border: 1px solid #eee;
  vertical-align: middle;
}
.table th {
  padding-inline: 11px;
}
.table th:first-child,
.table td:first-child {
  text-align: center;
}
.btn-add {
  background: #323232;
  border: 1px solid #323232;
  color: white;
  height: 40px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0 0.7rem 0 0.2rem;
  transition: all 0.3s ease-in-out;
}
.btn-add:hover,
.btn-add:hover,
.btn-add:hover {
  color: #323232;
  background-color: #fff;
  border-color: #323232;
}
.btn-add i {
  font-size: 1.6rem;
  font-weight: 600;
  margin-bottom: -0.05rem;
}
.form-container .table .form-group {
  min-width: 4rem;
}
.form-container .table .form-select {
  min-width: 6rem;
}
.form-container .table .form-select,
.form-container .table .form-control {
  font-size: 12px;
  padding: 0 0.5rem;
  height: 2rem;
}

/* generate pdf from images*/

.generatepdf {
  display: flex;
  justify-content: flex-end;
}

/* signature field syling */

.signature-container {
  text-align: center;
  padding: 10px;
  background-color: #f4f4f9;
  border-radius: 12px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 500px;
  margin: 0 auto;
  transition: all 0.3s ease;
}

.signature-container:hover {
  transform: scale(1.02);
}

.signature-title {
  font-family: "Arial", sans-serif;
  font-size: 18px;
  color: #444;
  font-weight: bold;
  margin-bottom: 20px;
}

.canvas-wrapper {
  position: relative;
  border-radius: 10px;
  overflow: hidden;
  background-color: #fff;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
}

.signature-canvas {
  width: 99%;
  height: 99%;
  border: none;
}

.buttons-container {
  margin-top: 10px;
}

.save-btn,
.clear-btn {
  background: linear-gradient(135deg, #0061ff, #00c6ff);
  color: white;
  border: none;
  padding: 7px 16px;
  margin: 4px;
  font-size: 14px;
  font-weight: bold;
  border-radius: 50px;
  cursor: pointer;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  width: 100px;
}

.save-btn:hover,
.clear-btn:hover {
  transform: translateY(-3px);
  box-shadow: 0 8px 25px rgba(0, 0, 0, 0.15);
}

.save-btn:active,
.clear-btn:active {
  transform: translateY(1px);
}

.mixbox-checkbox .toggle-box {
  border-radius: 4px;
  margin-left: 0.6rem;
}
/* .mixbox-checkbox::after {
  position: absolute;
  content: "";
  border: 1px solid #eee;
  top: 0.75rem;
  left: 2.5rem;
  right: 2.5rem;
} */
.mixbox-input {
  margin-left: 0.8rem;
}
