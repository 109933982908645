@import url("https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght@200..800&display=swap");

.form-wrapper {
  background: rgba(248, 249, 251, 1);
  font-family: "Plus Jakarta Sans", sans-serif;
}
.ff-jakarta {
  font-family: "Plus Jakarta Sans", sans-serif;
}
.form-wrapper .form-container {
  display: flex;
  flex-flow: column nowrap;
  margin-top: 1rem;
  min-height: calc(100vh - 7.5rem);
}
.form-wrapper .form-footer {
  position: sticky;
  bottom: 0;
}
.client-section {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  padding: 20px 25px;
  background: #fff;
  border-bottom: 1px solid rgba(224, 224, 224, 1);
}

.client-section > div {
  flex: 1;
  max-width: 20.5rem;
}

.client-section-first label {
  font-size: 12px;
  font-weight: bold;
}

.client-section-first h6 {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 0;
}

.client-section-first h5 {
  font-size: 12px;
  font-weight: 400;
  color: rgba(174, 173, 173, 1);
}

.client-select-second {
  display: flex;
  align-items: center;
  min-width: 20rem;
}
.client-select-second > div {
  width: 100%;
}

@media (max-width: 1024px) {
  .client-section > div {
    max-width: 48%;
  }
  .client-select-second {
    min-width: unset;
  }
}
@media (max-width: 568px) {
  .client-section {
    gap: 1rem;
  }
  .client-section > div {
    max-width: 100%;
  }
  .client-select-second {
    min-width: 16rem;
  }
}
.form-physical-stock {
  padding: 30px 25px;
  flex: 1;
}

.physical-stock-heading {
  line-height: 1;
  font-size: 22px;
  font-weight: 700;
  margin-bottom: 24px;
}

.physical-stock-sheet-outer,
.photos-upload-section,
.custom-field-section {
  border-radius: 10px;
  width: 100%;
  margin-top: 1rem;
  overflow: hidden;
  border: 1.5px solid #e0e0e0;
}

.physical-stock-header,
.custom-field-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.2rem 1.5rem;
  background-color: #fff;
  transition: all 0.3s ease-in-out;
}
.physical-stock-header:has(+ .physical-stock-main-content),
.custom-field-header:has(+ .form-row) {
  padding: 1.8rem 1.5rem;
  background-color: #f8f9fb;
}
.physical-stock-header h2,
.custom-field-header h2 {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 0;
  font-family: "Plus Jakarta Sans", sans-serif;
}
.icon-dropdown-first,
.icon-dropdown-second {
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: flex-end;
  min-width: 5rem;
}
.physical-stock-main-content {
  background: #fff;
  padding: 1.75rem 1.5rem;
  border-radius: 0 0 10px 10px;
}

.form-group1-description label {
  font-size: 12px;
  text-transform: uppercase;
}

.form-line {
  width: 100%;
  opacity: 1;
  margin: 3rem 0 2.35rem;
  border-color: #eeeeee;
}

/* upload photo section */

.photos-upload-header {
  display: flex;
  justify-content: space-between;
  padding: 0.8rem 1.5rem;
  background-color: #fff;
  transition: all 0.3s ease-in-out;
}
.photos-upload-header:has(+ .photos-upload) {
  padding: 1.35rem 1.5rem;
  background-color: #f8f9fb;
}

.photos-upload-header h3 {
  font-family: "Plus Jakarta Sans", sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  color: #000;
  margin: 0;
}

.photos-upload-header h2 {
  font-family: "Plus Jakarta Sans", sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 15px;
  color: rgba(174, 173, 173, 1);
  margin: 0;
}

.photos-upload {
  display: flex;
  flex-direction: column;
  gap: 25px;
  background-color: #fff;
  padding: 25px 1.5rem;
}

.upload-photo,
.open-camera {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  width: 48%;
  border-radius: 7px;
  border: 1.5px dashed;
}

.upload-photo {
  border-color: rgba(63, 109, 202, 1);
  background-color: rgba(248, 249, 251, 1);
}

.open-camera {
  border-color: rgba(27, 134, 80, 1);
  background-color: rgba(248, 251, 249, 1);
}

.upload-photo-text,
.open-camera-text {
  display: flex;
  flex-direction: column;
  gap: 5px;
  font-family: "Plus Jakarta Sans", sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 15px;
  color: rgba(116, 116, 116, 1);
}
.img-cell {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
}
.upload-photo-text h2,
.open-camera-text h2 {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 0;
  color: #323232;
}

.upload-photo-image {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  background-color: #ffffff;
  border: 1.5px solid rgba(224, 224, 224, 1);
}

.open-camera-image {
  margin: 0;
  border: 0;
  padding: 0;
  width: auto;
  background-color: #ffffff;
}

.upload-photo-image i,
.open-camera-image i {
  font-size: 18px;
  color: rgba(116, 116, 116, 1);
}

.remImg {
  margin: 0;
  border: 0;
  padding: 0;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: absolute;
  top: -0.5rem;
  right: -0.5rem;
  width: 1.125rem;
  height: 1.125rem;
  border-radius: 50%;
  background-color: #fec7c7; 
}

.img-cell {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
}

.img-cell .upload-btn i,
.capture-button-table i {
  font-size: 1.25rem;
}
.img-cell .upload-btn {
  margin: 0;
  cursor: pointer;
  margin-top: 3px;
}
.img-uploaded {
  position: relative;
}
.img-uploaded img {
  height: 3rem;
  aspect-ratio: 1;
  border-radius: 0.25rem;
  object-fit: cover;
}

.photos-preview {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 1.25rem;
  padding: 0 1.6rem 0;
}

.photos-preview:has(.preview-photo-container) {
  padding-bottom: 1.6rem;
}

.preview-photo-container {
  position: relative;
  border-right: 1px solid #bdbdbd;
}

.preview-photo-container:last-of-type {
  border: 0;
}

.preview-photo {
  width: 100%;
  height: 100px;
  object-fit: cover;
}

.delete-photo-button {
  position: absolute;
  top: -12px;
  right: 9.5px;
  margin: 0;
  background-color: #f44336;
  color: white;
  border: none;
  width: 1.5rem;
  height: 1.5rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

.container-camera-capture {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.video-container {
  position: relative;
  width: 100%;
  max-width: 300px;
  text-align: center;
}

.video {
  width: 100%;
  border: 2px solid #ddd;
  border-radius: 8px;
}

.capture-button,
.close-button {
  padding: 10px 18px;
  border: none;
  cursor: pointer;
  border-radius: 25px;
  background-color: #007bff;
  color: white;
}

.close-button {
  background-color: #f44336;
}

/* Custom field section */

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 5px;
  background-color: white;
}

.icon-dropdown-third {
  cursor: pointer;
}
.custom-field-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 40px;
  gap: 5px; /* Adjusted for better spacing between icon and text */
  border-radius: 5px;
  background-color: rgba(50, 50, 50, 1);
  cursor: pointer;
  margin: 10px 10px 0px 0px;
}

.custom-field-btn span {
  font-family: DM Sans;
  font-size: 14px;
  font-weight: 500;
  color: rgba(255, 255, 255, 1);
  text-align: center;
}
.form-footer {
  width: 100%;
  height: 95px;
  padding: 20px 1.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: rgba(255, 255, 255, 1);
  box-shadow: 0px -3px 40px rgba(0, 0, 0, 0.1);
}

.form-footer-content {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.auto-saved {
  display: flex;
  align-items: center;
  gap: 0.4rem;
}
.auto-saved i {
  color: #1b8650;
  font-size: 1.2rem;
}
.auto-saved span {
  font-size: 16px;
  font-weight: 600;
  color: #323232;
}
.form-footer-btn {
  display: flex;
  gap: 10px;
  opacity: 1;
}

.footer-btn {
  height: 54px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0 20px;
  border-radius: 5px;
  gap: 0.5rem;
  font-size: 1rem;
  min-width: 8rem;
  transition: all 0.3s ease-in-out;
}

.download-btn {
  color: #1b8650;
  background-color: rgba(243, 255, 250, 1);
  border: 1px solid rgba(24, 135, 84, 1);
}
.download-btn:hover,
.download-btn:focus,
.download-btn:active {
  opacity: 0.8;
  color: #1b8650;
  background-color: rgba(243, 255, 250, 1);
  border: 1px solid rgba(24, 135, 84, 1);
}

.submit-btn {
  border: 1px solid rgba(24, 135, 84, 1);
  background-color: rgba(24, 135, 84, 1);
  color: #ffffff;
}
.submit-btn:hover,
.submit-btn:focus,
.submit-btn:active {
  opacity: 0.8;
  border: 1px solid rgba(24, 135, 84, 1);
  background-color: rgba(24, 135, 84, 1);
  color: #ffffff;
}
.nsi-checkbox {
  margin-bottom: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* position: relative; */
}
.nsi-checkbox label {
  font-size: 14px;
}

.nsi-checkbox .toggle-box {
  border-radius: 4px;
}
.nsi-checkbox .toggle-label label {
  color: #525252;
}
.form-container .row {
  gap: 1.5rem 0;
}
.form-container label {
  margin-bottom: 0.35rem;
  font-size: 12px;
  font-weight: 600;
  color: #525252;
  transition: all 0.3s ease-in-out;
}
.form-container .form-control,
[class^="css-"][class$="-control"] {
  font-size: 14px;
  min-height: 40px;
}
textarea.form-control {
  padding-top: 12px;
}
.form-container .form-control::placeholder {
  color: #aeadad;
}
.form-container label:has(+ .form-control:focus),
.form-container
  label:has(
    + [class^="css-"][class$="-container"] > [class^="css-"][class$="-menu"]
  ) {
  color: #1b8650;
}
.form-container .table .form-select,
.form-container .table .form-control {
  border: 0;
}
.form-container .form-control:focus,
.form-container .form-select:focus,
.form-container .form-control:focus,
[class^="css-"][class$="-control"]:hover,
[class^="css-"][class$="-control"]:has(+ [class^="css-"][class$="-menu"]),
[class^="css-"][class$="-control"]:focus {
  outline: 0;
  border: 1px solid #1b8650;
  box-shadow: 0px 0px 0px 3px #1b86501a;
}

.form-container .form-select:focus {
  padding-left: 0.35rem;
}

.table th,
.table td {
  padding: 4px;
  border: 1px solid #eee;
  vertical-align: middle;
}
.table th {
  padding-inline: 11px;
  font-size: 15px;
}
.table th:first-child,
.table td:first-child {
  text-align: center;
}
.btn-add {
  background: #323232;
  border: 1px solid #323232;
  color: white;
  height: 38px;
  font-size: 14px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0 0.7rem 0 0.2rem;
  transition: all 0.3s ease-in-out;
}
.btn-add:hover,
.btn-add:hover,
.btn-add:hover {
  color: #323232;
  background-color: #fff;
  border-color: #323232;
}
.btn-add i {
  font-size: 1.5rem;
  margin-bottom: -0.02rem;
}
.form-container .table .form-group {
  min-width: 4rem;
}
.form-container .table .form-select,
.form-container .table .form-control {
  font-size: 12px;
  padding: 0 0.5rem;
  height: 2rem;
}
.form-container .table .form-select {
  min-width: 6rem;
  padding-left: 0.5rem;
  padding-right: 0;
}

.form-container .table textarea.form-control {
  font-size: 12px;
  padding: 0 0.5rem;
  min-height: 50px;
  height: unset;
  appearance: none;
  resize: none;
  min-width: 200px;
}
.generatepdf {
  display: flex;
  justify-content: flex-end;
}

.generate-pdf-button {
  padding: 10px 20px;
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.generate-pdf-button:hover {
  background-color: #45a049;
}

/* signature field syling */

.signature-container {
  text-align: center;
  padding: 10px;
  background-color: #f4f4f9;
  border-radius: 12px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  width: 100%;
  /* max-width: 500px; */
  margin: 0 auto;
  transition: all 0.3s ease;
}

.photos-upload-section-signature {
  border-radius: 10px;
  width: 100%;
  margin-top: 1rem;
  overflow: hidden;
  border: 1.5px solid #e0e0e0;
}

.photos-upload-header-signature {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.35rem 1.5rem;
  background-color: #fff;
  transition: all 0.3s ease-in-out;
}
.photos-upload-header-signature h2 {
  font-family: "Plus Jakarta Sans", sans-serif;
  font-size: 18px;
  font-weight: 500;
  line-height: 20px;
  color: #000;
  margin: 0;
}

.photos-upload-header-signature h5 {
  margin: 0;
  font-size: 1.2rem;
}

.form-control {
  font-size: 13px;
}

.icon-dropdown-second {
  display: flex;
  align-items: center;
}

.signature-container:hover {
  transform: scale(1.02);
}

.signature-title {
  font-size: 18px;
  color: #444;
  font-weight: 500;
  margin-bottom: 15px;
}

.canvas-wrapper {
  position: relative;
  border-radius: 6px;
  overflow: hidden;
  background-color: #fff;
  /* box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1); */
}

.signature-canvas {
  width: 99%;
  height: 99%;
  border: none;
  /* background-color: #45a049; */
}

.buttons-container {
  margin-top: 10px;
}

.save-btn,
.clear-btn {
  background: linear-gradient(135deg, #0061ff, #00c6ff);
  color: white;
  border: none;
  padding: 7px 16px;
  margin: 4px;
  font-size: 14px;
  font-weight: bold;
  border-radius: 50px;
  cursor: pointer;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  width: 100px;
}

.save-btn:hover,
.clear-btn:hover {
  transform: translateY(-3px);
  box-shadow: 0 8px 25px rgba(0, 0, 0, 0.15);
}

.save-btn:active,
.clear-btn:active {
  transform: translateY(1px);
}

.signature-preview {
  margin-top: 7px;
  text-align: center;
}

.signature-img {
  width: 150px;
  height: auto;
  border-radius: 0.35rem;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
}

.signature-preview h4 {
  font-size: 18px;
  font-weight: 600;
  color: #555;
}

.mixbox-checkbox {
  height: 2.5rem;
  margin-top: 1.8rem;
  display: flex;
  align-items: center;
}
.mixbox-checkbox .toggle-box {
  border-radius: 4px;
  margin-left: 0.6rem;
}
.mixbox-checkbox .toggle-label label {
  color: #525252;
}
.mixbox-input {
  margin-left: 1.8rem;
}
.btn-upload {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 2rem;
  gap: 0.35rem;
  font-weight: 500;
  padding: 0 0.65rem;
  transition: all 0.3s ease-in-out;
}
.btn-upload i {
  font-weight: 500;
}
.tb-bottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 1rem 1rem;
}

.table thead th,
.table thead td {
  font-weight: 500;
  box-shadow: none;
  vertical-align: middle;
  border: 0;
  font-size: 14px;
  background-color: #f5f5f5;
  border-top: 1px solid #e1e1e1;
  border-bottom: 1px solid #e1e1e1;
  border-left: 1px solid #e1e1e1;
}
.table thead th:last-child {
  border-right: 1px solid #e1e1e1;
}
.table tbody th,
.table tbody td {
  border: 0;
  font-size: 14px;
  vertical-align: middle;
  border-left: 1px solid #e1e1e1;
  border-bottom: 1px solid #e1e1e1;
}
.table thead th:last-child,
.table tbody td:last-child {
  max-width: 7.5rem;
  border-right: 1px solid #e1e1e1;
}
.table thead th:first-child {
  border-radius: 0.6rem 0 0 0;
  padding-right: 0.5rem;
}
.table thead th:last-child {
  border-radius: 0 0.6rem 0 0;
}
.table tbody tr:last-child td:first-child {
  border-radius: 0 0 0 0.6rem;
}
.table tbody tr:last-child td:last-child {
  border-radius: 0 0 0.6rem 0;
}
.signature-canvas {
  touch-action: none;
  user-select: none;
}

.video-container-table {
  position: relative;
  display: inline-block;
}

.video-table {
  width: 250px;
  height: 200px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.capture-button-table,
.close-button-table {
  position: absolute;
  bottom: 10px;
  left: 10px;
  background: rgba(255, 255, 255, 0.8);
  border: none;
  border-radius: 5px;
  padding: 5px;
  cursor: pointer;
}

.close-button-table {
  left: auto;
  right: 10px;
}

.tbl-footer {
  display: flex;
  align-items: center;
  padding: 0 1rem 0.85rem;
  justify-content: space-between;
}

@media (max-width: 992px) {
  .tbl-footer {
    gap: 0.5rem 1rem;
    flex-flow: row wrap;
  }
  .signature-title {
    font-size: 1rem;
    margin-bottom: 0.5rem;
  }
  .mixbox-input {
    margin-left: 1rem;
    min-width: unset;
  }
}
@media (max-width: 576px) {
  .physical-stock-header,
  .photos-upload-header,
  .photos-upload-header-signature {
    padding-inline: 1rem;
  }
  .physical-stock-heading {
    font-size: 1.25rem;
  }
  .mixbox-checkbox {
    margin-top: 0.5rem;
  }
}
@media (max-width: 415px) {
  .physical-stock-heading {
    font-size: 1.125rem;
  }
  .preview-photo-container {
    width: 6.2rem;
    border-right: 0;
  }
  .delete-photo-button {
    right: -12px;
  }
}
