@import url("https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght@200..800&display=swap");

.top-row {
  display: flex;
  align-items: center;
}
.page-title {
  font-size: 1.2rem;
  font-weight: 600;
}

.table {
  border: 0;
  font-size: 14px;
  margin-bottom: 0.1rem;
  font-family: "Plus Jakarta Sans", serif;
}
.table thead th {
  font-size: 13px;
  font-weight: 800;
  box-shadow: none;
  padding: 1rem 1rem;
  vertical-align: middle;
  background-color: #f8f9fb;
  border: 0;
  border-top: 1.5px solid #e1e1e1;
}
.table tbody td {
  border: 0;
  font-size: 13px;
  vertical-align: middle;
  padding: 0.5rem 0.5rem;
}
.table .greenText {
  color: #1b8650;
  font-weight: 500;
}
.table thead th:first-child {
  border-left: 1.5px solid #e1e1e1;
  border-radius: 10px 0 0 0;
  padding-left: 0.5rem;
  padding-right: 0;
  width: 9%;
}
.table thead th:last-child {
  border-right: 1.5px solid #e1e1e1;
  border-radius: 0 10px 0 0;
  max-width: 11.8rem;
  width: 5%;
}
.table tbody td:first-child {
  border-left: 1.5px solid #e1e1e1;
  padding-left: 0.5rem;
  padding-right: 0;
}
.table tbody td:last-child {
  border-right: 1.5px solid #e1e1e1;
  max-width: 11.8rem;
}
.table tbody tr:last-child td {
  border-bottom: 1.5px solid #e1e1e1;
}
.table tbody tr:last-child td:first-child {
  border-radius: 0 0 0 10px;
}
.table tbody tr:last-child td:last-child {
  border-radius: 0 0 10px 0;
}
.table thead th,
.table tbody td {
  border-bottom: 1.5px solid #e1e1e1;
}
/* Make the table allow dynamic resizing */
table {
  table-layout: auto; /* Allow columns to resize based on content */
  width: 100%;
}
.pagination-controls {
  font-family: "Plus Jakarta Sans", serif;
  display: flex;
  align-items: center;
  position: relative;
  margin: 0;
}

.ddbtn button {
  padding: 0;
  border: 0;
  position: relative;
  font-size: 14px;
  font-weight: 500;
  color: #323232;
  display: inline-flex;
  align-items: center;
  background-color: transparent;
  gap: 0.2rem;
}

.ddbtn button:hover,
.ddbtn button:focus,
.ddbtn button:active,
.ddbtn button.active,
.ddbtn button:checked + .btn,
.ddbtn button.show,
.btn:first-child:active,
:not(.ddbtn button) + .btn:active {
  color: #323232;
  background-color: transparent;
}
.ddbtn button span {
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  border-radius: 4px;
  border: 1px solid #c8c5c5;
  padding: 0.25rem 0.5rem 0.25rem 0.6rem;
}

.ddbtn button i {
  margin-top: 0.1rem;
  font-size: 0.75rem;
}

.ddbtn .dropdown-item {
  font-size: 13px;
  font-weight: 500;
}

.pagination {
  margin-left: auto;
  margin-bottom: 0;
}
.pagination .page-item .page-link {
  color: #323232;
  font-size: 13px;
  min-width: 2.2rem;
  text-align: center;
  font-weight: 600;
  border-color: #323232;
  background-color: #fff;
}
.pagination .page-item.active .page-link {
  color: #fff;
  background-color: #323232;
}

@media (max-width: 768px) {
  .page-title {
    white-space: nowrap;
  }
}
