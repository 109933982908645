.bGNKtt {
  flex: none;
}

.container-stats {
  padding: 20px;
}

.container-stats h2 {
  text-align: center;
  margin-bottom: 5px;
}

.container-expand { 
  background-color: #f8f9fa;
  border-left: 4px solid #007bff;
}

.stats-title-row {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 1rem;
  gap: 0.6rem;
  transition: 0.3s ease-in-out;
}

.stats-title-row .form-control {
  height: 2.4rem;
  width: 15rem;
}

.stat-tbl .bGNKtt {
  border: 0;
  vertical-align: middle;
  background-color: #f8f9fb;
  border-top: 1.5px solid #e1e1e1;
  border-left: 1.5px solid #e1e1e1;
  border-radius: 10px 0 0 0;
}
.stat-tbl .rdt_TableBody > div button {
  margin: 0;
  border-radius: 0;
  border-left: 1.5px solid #e1e1e1;
}
.stat-tbl .rdt_TableBody > div:last-of-type button {
  border-radius: 0 0 0 10px;
  border-bottom: 1.5px solid #e1e1e1;
}
.stat-tbl .rdt_Table .rdt_TableHead .rdt_TableCol:nth-child(2),
.stat-tbl .rdt_Table .rdt_TableBody .rdt_TableCell:nth-child(2) {
  max-width: 3rem;
  padding-right: 0;
  min-width: unset;
}
.stat-tbl .rdt_Table .rdt_TableHead .rdt_TableCol:nth-child(3),
.stat-tbl .rdt_Table .rdt_TableBody .rdt_TableCell:nth-child(3) {
  min-width: 30%;
  color: rgba(0, 0, 0, 0.87);
}
@media (max-width: 450px) {
  .stats-title-row {
    flex-flow: row-reverse;
  }
  .stats-title-row .form-control {
    width: auto;
    flex: 1;
  }
}
